import React, { useState, useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Modal } from 'bootstrap';

import SlideShow from '../components/slideShow';

const Gallery = ({ images }) => {
  const [clickedThumbnailIndex, setClickedThumbnailIndex] = useState(null);
  const [modal, setModal] = useState(null);

  const modalDomRef = useRef();

  const slides = images.map(image => ({
    title: image.title,
    file: image.fullSize,
  }));

  useEffect(() => {
    let bsModal = Modal.getInstance(modalDomRef.current);
    if (!bsModal) bsModal = new Modal(modalDomRef.current);
    setModal(bsModal);
  }, []);

  const handleThumbnailClick = (imageId) => {
    setClickedThumbnailIndex(slides.findIndex((s) => s.file.id === imageId));
    modal.show();
  };

  return (
    <>
      { images.length > 0
        ? <div className="row">
          { images.map(image => (
            <div className="col-sm-3" key={ image.src }>
              <a
                href={ image.fullSize.childImageSharp.gatsbyImageData.images.fallback.src }
                title={ image.title }
                onClick={ (e) => { e.preventDefault(); handleThumbnailClick(image.fullSize.id); }}
              >
                <figure className="figure">
                  { image.thumbnail
                    ? <GatsbyImage
                        image={ image.thumbnail.childImageSharp.gatsbyImageData }
                        alt={ image.title }
                        imgClassName="rounded"
                      />
                    : ''
                  }
                  { image.title
                    ? <figcaption className="figure-caption">{ image.title }</figcaption>
                    : ''
                  }
                </figure>
              </a>
            </div>
          ))}
        </div>
        : ''
      }
      <div
        className="modal modal-gallery fade"
        ref={ modalDomRef }
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-fullscreen-lg-down">
          <div className="modal-content">
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-body">
              <SlideShow slides={ slides } startSlide={ clickedThumbnailIndex }/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Gallery;
