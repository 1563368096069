import React from 'react';

const artworkFacts = ({
  artwork: {
    openingDate = null,
    location = null,
  },
}) => {
  let locationStr = null;
  let mapsHref = null;

  if (location && location.lat && location.lon) {
    mapsHref = `https://www.google.de/maps/@${ location.lat },${ location.lon },15z`;
  }

  if (location.city || location.country) {
    locationStr = [location.city, location.country].join(', ');
  }

  return (
    <dl>
      { openingDate ?
        <>
          <dt>Eröffnungsdatum</dt>
          <dd>
            <time dateTime={ openingDate }>{ new Date(openingDate).toLocaleDateString() }</time>
          </dd>
        </>
        : ''
      }
      { locationStr ?
        <>
          <dt>Ort</dt>
          <dd>
            <address>
              <span className="bi-geo-fill"></span>
              {
                mapsHref ?
                <a href={ mapsHref } target="_blank" rel="noreferrer">{ locationStr }</a>
                : locationStr
              }
            </address>
          </dd>
        </>
        : ''
      }
      
    </dl>
  );
};

export default artworkFacts;