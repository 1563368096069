import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Hero from '../components/hero';
import ArtworkFacts from '../components/artworkFacts';
import Gallery from '../components/gallery';

export const query = graphql`query ($slug: String) {
  playground(slug: {eq: $slug}) {
    title
    teaser
    description
    openingDate
    location {
      street
      city
      country
      lat
      lon
    }
    images {
      title
      src
    }
  }
  cover: file(
    relativeDirectory: {eq: $slug}, name: {in: "cover"}
  ) {
    id
    name
    childImageSharp {
      gatsbyImageData(width:1280, height: 400)
    }
  }
  thumbnails: allFile(
    filter: {relativeDirectory: {eq: $slug}, name: {nin: "cover"}}
  ) {
    nodes {
      id
      name
      childImageSharp {
        gatsbyImageData(width: 300, height: 250, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  }
  fullSize: allFile(
    filter: {relativeDirectory: {eq: $slug}, name: {nin: "cover"}}
  ) {
    nodes {
      id
      name
      childImageSharp {
        gatsbyImageData(height: 700, quality: 85, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
  }
}
`;

const ArtworkDetailPage = ({ data }) => {
  const images = data.playground.images.map(image => ({
    ...image,
    thumbnail: data.thumbnails.nodes.find(thumbnail => thumbnail.name === image.src.split('.')[0]),
    fullSize: data.fullSize.nodes.find(fullSize => fullSize.name === image.src.split('.')[0]),
  }));

  return (
    <Layout>
      <Seo title={ data.playground.title } />

      <Hero backgroundImage={ data.cover.childImageSharp.gatsbyImageData }>
        <h1>{ data.playground.title }</h1>
        <p className="lead">{ data.playground.teaser }</p>
        <ArtworkFacts artwork={ data.playground }/>
      </Hero>

      <div className="container-xl mt-3">
        <div dangerouslySetInnerHTML={{ __html: data.playground.description }} />
      </div>

      <div className="container-xl">
        <Gallery images={ images }/>
      </div>
    </Layout>
  );
};

export default ArtworkDetailPage;
